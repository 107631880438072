import React, { useEffect, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { MDBDataTable } from 'mdbreact';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { ServicesListProps } from '@digital.jit/services/customer/ui-models';
import moment from 'moment/moment';
import valueToColorType from '../../../helpers/valueToColorType';
import statusCheck from '../../../helpers/statusCheck';
import valueToColor from '../../../helpers/valueToColor';
import { IServicesTable } from '../../../interfaces/Services';
import { routes } from '../../../constants/routes';
import listSorting from '../../../helpers/list-sort';
import tasksProgress from '../../../helpers/tasksProgress';
import { dateFormat } from '../../../constants/dateFormat';
import styles from './services.module.scss';

const ServicesTable = ({
  hiddenCustomerColumn,
  servicesList,
  displayEntries,
  paging,
  isShowStatus,
  info,
  recordsFoundLabel,
}: IServicesTable) => {
  const { t } = useTranslation();
  const router = useRouter();
  const [serviceListData, setServiceListData] = useState<ServicesListProps[]>(
    servicesList instanceof Array ? servicesList : [],
  );

  const stopPropagation = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.KeyboardEvent,
  ) => {
    e.stopPropagation();
  };
  const data = {
    columns: [
      {
        label: `${t('ID')}`,
        field: 'id',
        width: 40,
        sort: 'disabled',
        attributes: {
          className: styles['table-header-title'],
        },
      },
      {
        label: `${t('servicesPage.service')}`,
        field: 'serviceType',
        sort: 'asc',
        width: 100,
      },
      {
        label: `${t('servicesPage.customerTitle')}`,
        field: 'customer',
        sort: 'asc',
        width: 100,
      },
      {
        label: `${t('servicesPage.address')}`,
        field: 'address',
        sort: 'asc',
        width: 100,
      },
      {
        label: `${t('servicesPage.startDate')}`,
        field: 'startDate',
        sort: 'asc',
        width: 100,
      },
      {
        label: `${t('servicesPage.dueDate')}`,
        field: 'deliveryDate',
        sort: 'asc',
        width: 100,
      },
      {
        label: `${t('servicesPage.tasks')}`,
        field: 'tasks',
        width: 50,
        sort: 'disabled',
        attributes: {
          className: styles['table-header-title'],
        },
      },
    ],
    rows: (serviceListData instanceof Array ? serviceListData : []).map(
      (item) => {
        const progressNumber = tasksProgress(item.tasksNumber, item.tasksReady);
        const progressBarWidth = item?.isDone ? 100 : progressNumber;
        return (
          item && {
            ...item,
            id: <p className={styles.id}>Ser_{item.id.substr(0, 3)}</p>,
            ...(!hiddenCustomerColumn && {
              customer: (
                <div className={styles.customer}>
                  <h3 className={styles['customer-name']}>
                    {item.customer.firstName} {item.customer.lastName}
                  </h3>
                  <p className={styles['customer-phone-number']}>
                    {item.customer.phoneNumber}
                  </p>
                  <p>{item.customer.companyName}</p>
                </div>
              ),
            }),
            tasks: <div className={styles.tasks}>{item.tasksNumber}</div>,
            startDate: (
              <div className={styles.startDate}>
                <p>
                  {item.startDate && moment(item.startDate).format(dateFormat)}
                </p>
              </div>
            ),
            deliveryDate: (
              <div
                role="button"
                tabIndex={0}
                onKeyDown={stopPropagation}
                onClick={stopPropagation}
              >
                <p className={styles.date}>
                  {item.deliveryDate &&
                    moment(item.deliveryDate).format(dateFormat)}
                </p>
                <p
                  className={styles['progress-value']}
                  style={{
                    color: valueToColor(progressNumber, item?.isDone),
                  }}
                >
                  {statusCheck(progressNumber, item?.isDone, t)}
                </p>
                <ProgressBar
                  now={progressBarWidth}
                  variant={valueToColorType(progressNumber, item?.isDone)}
                  className={`${styles.progress} 'bg-transparent progress-sm'`}
                />
              </div>
            ),
            // will be implemented later
            status: (
              <p
                className={`${styles['payed-status']}
          ${
            item.statusPayed
              ? styles['payed-status-true']
              : styles['payed-status-false']
          }`}
              >
                {item.statusPayed
                  ? `${t('servicesPage.payed')}`
                  : `${t('servicesPage.unpaid')}`}
              </p>
            ),
            clickEvent: () => {
              router.push(routes.services.path(item.id));
            },
          }
        );
      },
    ),
  };

  const columnWithoutCustomer = data.columns.filter(
    (item) => item.field !== 'customer',
  );

  if (hiddenCustomerColumn) {
    data.columns = columnWithoutCustomer;
  }

  const sorting = (obj: { column: string; direction: string }) => {
    setServiceListData([
      ...listSorting<ServicesListProps>(
        obj.column,
        obj.direction,
        serviceListData,
      ),
    ]);
  };

  if (!isShowStatus) {
    data.rows.map((item) => {
      // eslint-disable-next-line no-param-reassign
      delete item.status;
      return item;
    });
    data.columns.pop();
  }

  useEffect(() => {
    if (servicesList instanceof Array) {
      setServiceListData(servicesList);
    }
  }, [servicesList]);

  return (
    <MDBDataTable
      key={servicesList.length}
      responsive
      searching={false}
      data={data}
      theadColor="#333"
      paginationLabel={['<', '>']}
      hover
      displayEntries={displayEntries}
      className={styles['services-list']}
      info={info}
      paging={paging}
      onSort={sorting}
      noRecordsFoundLabel={recordsFoundLabel}
      onPageChange={() =>
        window.scrollTo({
          top: document.getElementById('table-header').offsetHeight,
        })
      }
    />
  );
};

export default ServicesTable;
