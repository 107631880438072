import Link from 'next/link';
import React from 'react';
import { Card, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IServicesList } from '../../../interfaces/Services';
import ServicesTable from '../services-table';
import { routes } from '../../../constants/routes';
import CustomSpinner from '../../spinner';
import shouldShowPagination from '../../../helpers/should-show-pagination';
import styles from './service-list.module.scss';

const ServicesList = ({
  title,
  isCount,
  servicesList,
  isCreateNewService,
  isShowStatus,
  isShowAllTasks,
  isLoading,
  recordsFoundLabel,
}: IServicesList) => {
  const { t } = useTranslation();

  return (
    <Card>
      <Card.Body>
        <div id="table-header" className={styles['table-header']}>
          <h2 className={styles['table-header-title']}>
            {t(title)}
            {isCount ? ` (${servicesList.length})` : null}
          </h2>
          {isCreateNewService ? (
            <Link href={routes.createService.path()}>
              <a className={`${styles['service-add-link']} btn btn-success `}>
                <i className="bx bx-plus" /> {t(routes.createService.title)}
              </a>
            </Link>
          ) : null}
        </div>
        {isLoading ? (
          <CustomSpinner />
        ) : (
          <ServicesTable
            servicesList={servicesList}
            displayEntries={false}
            paging={shouldShowPagination(servicesList)}
            info={false}
            isShowStatus={isShowStatus}
            recordsFoundLabel={recordsFoundLabel}
          />
        )}
        {isShowAllTasks && (
          <Row>
            <Link href={routes.services.path()}>
              <a className={styles['view-all-task-btn']}>
                {t('dashboard.services.viewAllServices')}
              </a>
            </Link>
          </Row>
        )}
      </Card.Body>
    </Card>
  );
};

export default ServicesList;
